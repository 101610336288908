import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function GstReturnFilling() {
  /* Slider */
  // const regFillSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `GST Return Filing Online In India`,
  //     content: `India's Best GST Return Filing Online`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Best Online GST Return Filing  Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online GST Return Filing`,
  //     content: `Best GST Return Filing Online`,
  //     image: "/imgs/registration/msme/msme-slide.png",
  //     alt_tag: "Best Online GST Return Filing Online in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online GST Return Filing Service`,
  //     content: `Best GST Return Filing  Service Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online GST Return Filing Online in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `GST Return Filing`,
    buyBtnLink: `#pricing-buy`,
    price: `999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data With Form*/
  const regFillAboutData = {
    header: `GST Return Filing Online In India`,
    sub_title: `For Personal and Business Related GST Return Filings with the TODAYFILINGS Experts.`,
    content_paragraph: [
      // `Registered dealer must file a GST return since it contains information about their
      // income return under the GST. All regular taxpayers who have registered for return filing
      //  must submit Form GSTR-1. TODAYFILINGS offers 100% online GST filing. `,

      `In India, tax officials have authority over the GST filing 
      process to determine the amount of tax due. The procedure of filing a GST return in India serves as a 
      conduit between the taxpayer and the government.Concentrate on your business and let TODAYFILINGS handle the filings and procedures. Get simple compliance
      with the help of the CA to submit your GST return online.`,
    ],
  };

  /* Scroll Nav Data */
  const regFillScrollId = [
    {
      id: `#GST`,
      heading: `GST Types
      `,
    },
    {
      id: `#Categories`,
      heading: `Different Categories`,
    },
    {
      id: `#Procedure`,
      heading: `Registration Procedure`,
    },
    {
      id: `#Penalty`,
      heading: `Penalty`,
    },
  ];

  /* Img Content Component Data */
  const regFillIcData = {
    id: 'GST',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Different Types Of GST Return Filing Invoices In India',
    image: '/imgs/registration/msme/ms-me.png',
    alt_tag: 'GST Return Filing Procedure',
    Paragraph: `GST Return Filing receipt is used to identify a bill of stock, albeit it does not state 
    that it gently excludes any assessment fees because the dealer cannot compel the customer to file a 
    GST return. It is granted when a cost cannot be reimbursed: when a registered person who has chosen the
    "composition plan" and is selling banned items or services.`,
    points: [
      {
        head: `Total Invoice`,
        content: `The seller may regularly issue a combined or mass receipt for all of the different invoices 
        if the utility of each bill is less than Rs. 200 and the buyer is not registered.`,
        icon: true,
      },
      {
        head: `Debit And Credit Note`,
        content: `When the amount that the buyer must pay the seller increases and the tax invoice's taxable 
        value decreases, the vendor issues a debit note.`,
        icon: true,
      },
      {
        content: `When the cost of receipt decreases, the tax invoice has a greater taxable value, the buyer 
        discounts the products to the supplier, or the services are found to be subpar, the vendor will issue 
        a credit note.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const regFillCfData = {
    id: 'Categories',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'GST Return Filing Online In India',
    content: ``,
    body: [
      {
        head: 'GSTR-1',
        points: `GSTR 1 is used to announce the issuance of debit and credit notes as well as to divulge 
        information about all endeavours, transactions, and outward product supplies that were made throughout 
        a tax period.`,
        icon: true,
      },
      {
        head: 'GSTR-2A',
        points: `The GSTR-2 return, which is based on information provided by the providers in their GSTR-1
         return, includes specifics of all internal purchases of goods and services, including those made via 
         verified providers within an expense period. Since it is a read-only return, nothing should be done. 
         `,
        icon: true,
      },
      {
        head: 'GSTR-3B',
        points: `GSTR-3B is a self-affirmation that must be filed every month in order to provide information 
        on any outward stocks made, input tax credits claimed, tax accounts established, and taxes paid. `,
        icon: true,
      },
      {
        head: 'GSTR-4- (CMP-08)',
        points: `Taxpayers who choose the "Composition Scheme" under the GST are required to submit their GST 
        returns online in India.`,
        icon: true,
      },
      {
        head: 'GSTR-5',
        points: `It is the return that non-resident, unfamiliar taxpayers under GST must record in order to 
        convey business transactions in India.`,
        icon: true,
      },
      {
        head: 'GSTR-6',
        points: `It is a monthly return that a "Input Service Distributor" must submit (ISD). It will reveal the specifics of the ISD's information tax reduction that was acquired and appropriated. Regularity: Monthly.`,
        icon: true,
      },
      {
        head: 'GSTR-7',
        points: `People who must deduct TDS (Tax deducted at source) in accordance with GST 7 must submit a monthly 
        return that must be recorded.`,
        icon: true,
      },
    ],
  };

  /* Image Slider */
  const imgSlider = [
    '/imgs/business/checklist.png',
    '/imgs/business/llp.png',
    '/imgs/registration/msme/ms-me.png',
  ];

  /*  Slider Content Component Data */
  const regFillScData = {
    id: 'Procedure',
    background: 'main-content',
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: 'New GST Return Filings In India',
    paragraph: `The 31st GST Council Meeting stated that taxpayers would also receive the GST returns. For the 
    ease of filing by taxpayers registered for the GST, this return framework will include simple return 
    structures. Except for small taxpayers, there will be one major return, GST RET-1 and 2 annexures, GST ANX-1
     and GST ANX-2, to be presented each month (turnover up to Rs 5 crore in the former money related year who 
      can select to record a similar quarterly).`,
    content: [
      {
        head: 'Significant Changes included in the New GST Return Filing in India System',
        points: `In order to provide details during documentation while using a different HSN outline, a Harmonized System of Nomenclature 
        code will undoubtedly be required. Additionally, a user will receive "HSN" via GST ANX-2 everywhere a 
        supplier is required to disclose the HSN code.`,
        icon: true,
      },

      {
        points: `The B2B supply mechanism should not be displayed by the provider in the "GST ANX-1," but the entire amount must be displayed in the "GST RET-1."`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /*  Content Img Component Data */
  const regFillCiData = {
    id: 'document',
    background: '',
    mt_div: 'mt-3',
    header: 'Different Categories Of GST Return Filing',
    paragraph: ``,
    points: [
      {
        head: `GSTR-8`,
        content: `It is a monthly report that GST-enrolled online business owners are required to submit (TCS).`,
        icon: true,
      },
      {
        head: `GSTR-9`,
        content: `It is the annual return that GST-enrolled taxpayers are required to file. All taxpayers who 
        have registered for GST* must submit one. `,
        icon: true,
      },
      {
        head: `GSTR-10`,
        content: `A taxable person whose enrollment has been cancelled or abandoned must submit a GSTR 10 return.
         Rate: one-fourth of a year after the request is deleted.`,
        icon: true,
      },
      {
        head: `GSTR-11`,
        content: `It is the return that individuals with a Unique Identifying Number (UIN) must submit in order 
        to qualify for a GST discount on the goods and services they purchased in India.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme-img.png',
    alt_tag: 'Required Documents for GST Return Filling Registration',
  };

  /*  Content Img Component Data */
  const PenaltyIcData = {
    id: 'Penalty',
    background: '',
    mt_div: '',
    header: 'Penalty For Not Filing GST Returns',
    paragraph: ``,
    points: [
      {
        head: ``,
        content: `Delayed GST filing information could have a ridiculous effect, leading to hefty fines and 
        punishment. The taxpayers will be required to pay interest and the late fee.`,
        icon: true,
      },
      {
        head: ``,
        content: `If GST return filings are not 
        recorded within the specified deadline. Additionally, 18% annual enthusiasm would be feasible.`,
        icon: true,
      },
      {
        head: ``,
        content: `In any event, 
        the taxpayer has a chance to gauge public opinion regarding the size of the upcoming special assessment.`,
        icon: true,
      },
      {
        head: ``,
        content: `The Late Fee includes Rs. 100 each day as per the Act, as well as Rs. 100 under CGST and Rs. 100 under SGS, 
        for a total of Rs. 200 per day. The bigger sum will bring roughly Rs. 5000. `,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/ms-me.png',
    alt_tag: 'Required Documents for GST Return Filling Registration',
  };

  /* FaQ data */
  var GSTReturnFAQ = [
    {
      header: 'What do I have to do if E-sign does not work?',
      body: [
        {
          content: `An international standard body called ISO 9001 lays out the
          requirements for various entities' quality management systems (QMS).`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: `What do I do if my merchant doesn't transfer my receipt in their
      GSTR-1?`,
      body: [
        {
          content: `Ensuring that the purchase is recorded in your GSTR-2.`,
          icon: false,
        },
      ],
    },
    {
      header: `Under what conditions can Form RET-1 be recorded through SMS?`,
      body: [
        {
          content: `An organization can receive approval to use an ISO management system
          by receiving an ISO certification, which is a document given by an
          independent third party.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: ` Who are Input Service Distributors (ISD)? What returns do they need
        to document?`,
      body: [
        {
          content: `The corporate office of a company that sells goods and services is
          the one that is taxed when businesses make requests for internal
          supplies for the benefit of branch offices in order to spread tax
          savings. expensive.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `What are the profits to be given by a 'non-resident' taxable
      assessee?`,
      body: [
        {
          content: ` A non-resident taxable assessee is required to file GST Return -
          GSTR-5, which includes details of monthly "inward" and "outward"
          supply, debit/credit notes, tax paid information, information on
          closing stock, and any claimed refunds, if any.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the types of regular returns available for a taxpayer who chooses to file his returns quarterly?`,
      body: [
        {
          content: `A small taxpayer (having aggregate turnover of up to Rs. 5 crore in the previous year) has the option 
          to file either Sahaj, Sugam, or Quarterly (Normal) return.`,
          icon: false,
        },
      ],
    },
    {
      header: `Can the taxpayer change the period of filing his return?`,
      body: [
        {
          content: `The taxpayer will have the option to change the period (from quarterly to monthly and vice versa)
           of filing his returns only once – at the time of filing his first return for that financial year.`,
          icon: false,
        },
      ],
    },
    {
      header: `When will the supplier be intimated that the recipient has rejected the document uploaded by him?`,
      body: [
        {
          content: `The rejected documents shall be intimated to the supplier only after the recipient has filed his return.`,
          icon: false,
        },
      ],
    },
    {
      header: `Can the supplier make an amendment in documents that are left pending by the recipient?`,
      body: [
        {
          content: `No, the recipient has to reject the document in order to allow the supplier to carry out any amendment.`,
          icon: false,
        },
      ],
    },
    {
      header: `Certain details have been auto-populated into my Form RET-1. What is the source for these details?`,
      body: [
        {
          content: `The information that had been previously declared in the relevant Form ANX-1 and Form ANX-2 will be auto-populated into the Form RET-1.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Gst Return Filling'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={regFillSlider} /> */}
          <Package packageData={PackageCompData} />
          <AboutWithForm about_content={regFillAboutData} />
          <Counter />
          <ScrollNav scroll_data={regFillScrollId} />
          <ImgContent item={regFillIcData} />
          <ContentForm CFSection_data={regFillCfData} />

          <ContentImg CISection_data={regFillCiData} />
          <SliderContent ScSection_data={regFillScData} />
          <ContentImg CISection_data={PenaltyIcData} />

          <Cta />
          <Guidance />

          <FAQAccord items={GSTReturnFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
